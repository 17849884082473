
export default {
  animation: function () {
    (function (window, $) {
      $( document ).ready(function() {
        console.log( "ready!" );
      });

      // ScrollTO btns

      $(".menu-header a").on("click", function() {
        const value = $(this).attr("id");
        const header = $(".header").height();

        $("html, body").animate({ scrollTop: $('.'+value).offset().top - header }, 700);
      })



    })(window, jQuery);
  }
}

'use strict'

import global from './_global';
import lazyLoad from './_lazy-load';
import animations from './_animations'
import header from './_header'


document.addEventListener("DOMContentLoaded", function () {

    global.pageLoadedChecker();
    lazyLoad.lazyLoad();
    header.burgerMenu();
    animations.animation();

});


export default {

  pageLoadedChecker: function () {
    (function (window, $) {
    const html = document.querySelector('html');

    function isCoarse() {
      const match = matchMedia('(pointer:coarse)').matches;
      if (match) {
        html.classList.add('coarse');
      }
    }

    let everythingLoaded = setInterval(function () {
      if (/loaded|complete/.test(document.readyState)) {
        clearInterval(everythingLoaded);

        html.classList.add('page-loaded');

        isCoarse();
      }
    }, 10);




    })(window, jQuery);
  },


  cursorCustom: function () {
      var cursor = document.querySelector('.cursor');
      var cursorinner = document.querySelector('.cursor2');
      var bigcursor = document.querySelectorAll('.bigcursor');
      var glide = document.querySelectorAll('.glide');
      var a = document.querySelectorAll('a');

      document.addEventListener('mousemove', function(e){
          var x = e.clientX;
          var y = e.clientY;
          cursor.style.left = x + "px";
          cursor.style.top = y + "px";
      });

      document.addEventListener('mousemove', function(e){
          var x = e.clientX;
          var y = e.clientY;
          cursorinner.style.left = x + 'px';
          cursorinner.style.top = y + 'px';
      });

      document.addEventListener('mousedown', function(){
          cursor.classList.add('click');
          cursorinner.classList.add('cursorinnerhover')
      });

      document.addEventListener('mouseup', function(){
          cursor.classList.remove('click')
          cursorinner.classList.remove('cursorinnerhover')
      });

      document.addEventListener('click', () => {
          cursor.classList.add("expand");

          setTimeout(() => {
              cursor.classList.remove("expand");
          }, 500)
      })

      a.forEach(item => {
          item.addEventListener('mouseover', () => {
              cursor.classList.add('hover');
          });
          item.addEventListener('mouseleave', () => {
              cursor.classList.remove('hover');
          });
      });

      bigcursor.forEach(item => {
          item.addEventListener('mouseover', () => {
              cursor.classList.add('hover');
          });
          item.addEventListener('mouseleave', () => {
              cursor.classList.remove('hover');
          });
      });

      glide.forEach(item => {
          item.addEventListener('mouseover', () => {
              cursor.classList.add('d-none');
          });
          item.addEventListener('mouseleave', () => {
              cursor.classList.remove('d-none');
          });
      });

      glide.forEach(item => {
          item.addEventListener('mouseover', () => {
              cursorinner.classList.add('d-none');
          });
          item.addEventListener('mouseleave', () => {
              cursorinner.classList.remove('d-none');
          });
      });
  }
}

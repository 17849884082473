export default {
  burgerMenu: function() {
    (function (window, $) {

      $('.header__burger').on('click', function(){
        $(this).toggleClass('open');
        $('.burger__menu').toggleClass('open-menu');
        $('.header__logo').toggleClass('logo-small');
      });

    })(window, jQuery);
  }
}
